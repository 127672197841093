import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import Container from '../layout/Container'
import { useHistory, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { StateSelectable, NameDescKeyword, Props } from '../../store/types.d'
import SingleTemplateStore from '../../store/SingleTemplateStore'
import ParagraphStore from '../../store/Paragraph'
import StateSelector from '../commonComponent/StatesSelector'
import FormModal from '../commonComponent/FormModal'
import classNames from 'classnames'
import ReactEditor from './ReactEditor'
import { EditorState, ContentState } from 'draft-js'
import parse from 'html-react-parser'
import { replaceSpaceWithHyphen, DeleteButton } from '../commonComponent/commonFuction'
import { Article } from '../commonComponent/DotLoader'
import htmlToDraft from 'html-to-draftjs'
import { Breadcrumb } from '../commonComponent/BreadcrumbComponent'
import { Preview } from '../commonComponent/PreviewStyle'
import uuid from 'react-uuid'
import { toast } from 'react-toastify'
import { TableLoader } from '../commonComponent/Loader'

interface DeleteProps {
    store: SingleTemplateStore
    item: any
}

const initialParagraph = {
    name: '',
    description: '',
    // keywords: [],
    body: '',
    edit_history: []
}

export interface StateSelectorButtonProps {
    targetStore: StateSelectable
    onClick: (store: StateSelectable) => void
    isDisable?: boolean
}

export const StateSelectorButton: React.FC<StateSelectorButtonProps> = ({ targetStore, onClick, isDisable }) => {
    const is_all_states = targetStore.is_all_states
    const states = targetStore.states
    const disable = isDisable || false
    return <button
        className='btn btn-sm btn-link'
        disabled={disable}
        onClick={() => {
            onClick(targetStore)
        }} >States ({(is_all_states) ? 'All' : states.length})
    </button>
}

interface EditIconProps {
    onClick: () => void
    title: string
}

const EditIcon: React.FC<EditIconProps> = ({ onClick, title }) => {
    return <button
        type='button'
        onClick={onClick}
        className='btn btn-sm mr-1'
        title={title}>
        <span><FontAwesomeIcon icon={faEdit} /></span>
    </button>
}

const AddParagraphButton: React.FC<EditIconProps> = ({ onClick, title }) => {
    return <div className="plus-circle text-center my-2">
        <button
            type="button"
            className="btn btn-sm btn-dark plus-button"
            onClick={onClick}
            title={title}>
            <span><FontAwesomeIcon icon={faPlusCircle} /></span>
        </button>
        <span className="add-para d-block ms-2">Add Paragraph</span>
    </div>
}

const UpdateTemplatePage: React.FC<Props> = ({ rootStore }) => {

    const { register, handleSubmit, errors } = useForm()

    const [shouldHideExtraFields, SetShouldHideExtraFields] = useState(false)
    const [modalShow, setModalShow] = useState(false)
    const [modalTitle, setModalTitle] = useState('')

    const { authStore, allStatesStore, templateStore, clientStore, monacoEditorStore, debugStore, setTitle } = rootStore
    const { isDeedUser } = authStore
    const { statesWithValueLabel } = allStatesStore
    const { resetLoadingProcess, previewDocument, setPreviewDocument, setCurrentMatter } = clientStore
    const { getOneTemplateDetails, templateDetails, isApiError, isLoading, apiErrorMessage, setPreviewDocumentInTemplateDetails } = templateStore

    const history = useHistory()

    const { clientId, matterId, documentId, spouse, UUID } = useParams<{
        clientId: string,
        matterId: string,
        documentId: string,
        spouse: string,
        UUID: string
    }>()

    const isMatterDocument = (history.location.pathname.includes('/clients/')) ? true : false
    const isHTMLDocument = (history.location.pathname.includes('/HTML')) ? true : false

    const [targetStore, setTargetStore] = useState<StateSelectable>()

    const [addEditStore, setAddEditStore] = useState<SingleTemplateStore>()
    const [showStateModal, setShowStateModal] = useState(false)
    const [paragraph, setParagraph] = useState<ParagraphStore>()

    const [editorState, setEditorState] = useState<EditorState>()
    const [deleteItemWithStore, setDeleteItemWithStore] = useState<DeleteProps>()

    const [modalData, setModalData] = useState<NameDescKeyword | ParagraphStore>()

    useEffect(() => {
        if ((templateDetails !== undefined && templateDetails.name === '') || (templateDetails !== undefined && documentId !== templateDetails._id)) {
            if (isHTMLDocument) {
                if (previewDocument._id === '' || previewDocument._id !== documentId) {
                    const forSpouse = (spouse === 'true') ? true : false
                    setPreviewDocument(matterId, documentId, forSpouse, UUID)
                }
                setPreviewDocumentInTemplateDetails()
            } else if (isMatterDocument) {
                getOneTemplateDetails(documentId, matterId)
                debugStore.setTemplateId(documentId)
            } else {
                getOneTemplateDetails(documentId, '')
                debugStore.setTemplateId(documentId)
            }
            if (isMatterDocument) {
                setCurrentMatter(matterId)
            }
        }
    }, [clientId, getOneTemplateDetails, isMatterDocument, matterId, resetLoadingProcess, templateDetails, documentId, isHTMLDocument, previewDocument._id, spouse, setPreviewDocument, setPreviewDocumentInTemplateDetails, UUID, debugStore, setCurrentMatter])


    const openCloseModal = () => {
        const show = (modalShow === false) ? true : false
        setModalShow(show)
    }

    const onActionClicked = (action: string, store: any, data: any) => {

        setDeleteItemWithStore({ item: data, store: store })
        if (action === 'addParagraph') {
            setModalData({ ...initialParagraph })
            setEditorState(undefined)
            setModalTitle('Add Paragraph')
            openCloseModal()
            setAddEditStore(data)
        }

        if (action === 'editParagraph') {
            const markdownString = (data.body) ? data.body.replace(/pt;/g, 'px;').replace(/<pre style="word-break: break-word; white-space: pre-wrap; text-align: justify;">/g, '<pre>') : ''
            const blocksFromHTML = htmlToDraft(markdownString)
            const { contentBlocks, entityMap } = blocksFromHTML
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
            const editorState = EditorState.createWithContent(contentState)
            setEditorState(editorState)
            setModalData({ name: data.name, description: data.description, keywords: data.keywords, body: data.body })
            setModalTitle('Edit Paragraph')
            openCloseModal()
            setParagraph(data)
            SetShouldHideExtraFields(true)
        }
    }

    const onSaveModelForAddArticle = async () => {
        if (modalTitle === 'Add Paragraph' && addEditStore !== undefined && modalData !== undefined) {
            addEditStore.addNewParagraph(modalData.name, modalData.description, modalData.body || '', [], (paragraph) => {
                setModalTitle('Edit Paragraph')
                setParagraph(paragraph)
                SetShouldHideExtraFields(true)
                setDeleteItemWithStore({ item: paragraph, store: addEditStore })
            })
        }

        if (modalTitle === 'Edit Paragraph' && paragraph !== undefined) {
            if (paragraph.body === modalData?.body && paragraph.name === modalData?.name && paragraph.description === modalData?.description) {
                toast.warning('You have not made any changes.')
                return
            }
            paragraph.name = modalData?.name || ''
            paragraph.description = modalData?.description || ''
            paragraph.body = modalData?.body || ''
        }
        if (isMatterDocument === true) {
            const response = await templateStore.updateTemplate(true, matterId)
            if (response.success === 1) {
                toast.success(response.message)
            } else {
                toast.error(response.message)
            }
        } else {
            const response = await templateStore.updateTemplate(false, '')
            if (response.success === 1) {
                toast.success(response.message)
            } else {
                toast.error(response.message)
            }
        }
    }

    const handleOnDelete = async (store: SingleTemplateStore, removeItem: any, openClose: () => void) => {
        const message = `Are you sure you want to delete "${removeItem.name}" ${removeItem.type} ?`
        if (window.confirm(message)) {
            store.remove(removeItem)
            if (isMatterDocument === true) {
                const response = await templateStore.updateTemplate(true, matterId)
                if (response.success === 1) {
                    toast.success(`${removeItem.name}" ${removeItem.type} deleted`)
                } else {
                    toast.error(`${removeItem.name}" ${removeItem.type} not deleted`)
                }
            } else {
                const response = await templateStore.updateTemplate(false, '')
                if (response.success === 1) {
                    toast.success(`"${removeItem.name}" ${removeItem.type} deleted`)
                } else {
                    toast.error(`"${removeItem.name}" ${removeItem.type} not deleted`)
                }
            }
        }
        openClose()
    }

    const openCloseStateModal = () => {
        const show = (showStateModal === false) ? true : false
        setShowStateModal(show)
    }

    const articleModalWithChildModal = () => {

        const handleOnChangeName = (e: any) => {
            // if (modalData !== undefined) {
            modalData.name = e.target.value.trim()
            setModalData(modalData)
            // }
        }

        const handleOnChangeDescription = (e: any) => {
            // if (modalData !== undefined) {
            modalData.description = e.target.value.trim()
            setModalData(modalData)
            // }
        }

        const handleOnEditorChange = (data: any) => {
            // if (modalData !== undefined) {
            modalData.body = data
            setModalData(modalData)
            // }
        }

        const showHiddenFields = () => {
            SetShouldHideExtraFields(!shouldHideExtraFields)
        }

        const popup_title = `${modalTitle}:  ${modalData?.name}`

        return <FormModal modalTitle={popup_title} modalDialogClass='modal-fullscreen' show={modalShow} openCloseModal={openCloseModal}>
            <>
                <form onSubmit={handleSubmit(onSaveModelForAddArticle)} className='modal-body'>
                    {templateDetails.isLoading === true && <TableLoader isLoading={templateDetails.isLoading} />}
                    {templateDetails.isApiError && templateDetails.isLoading === false && <div className='responsive alert-danger p-3 mb-4 rounded' > {templateDetails.apiErrorMessage} </div>}
                    <div className='row'>
                        <div className='col'>
                            <button className='btn btn-outline-primary' type='button' onClick={showHiddenFields}> {shouldHideExtraFields ? 'Show' : 'Hide'} Hidden Fields </button>
                        </div>
                        <div className='col text-right'>
                            <button type="button" className="btn btn-outline-secondary mr-2" data-dismiss="modal" onClick={openCloseModal}>Cancel</button>
                            <button type="submit" className="btn btn-outline-primary mr-1">Update</button>
                        </div>
                    </div>
                    <fieldset>
                        <div className={classNames('form-group', { 'd-none': shouldHideExtraFields })}>
                            <label className='mb-0 mt-2'>Title *</label>
                            <input type='text'
                                className={classNames('form-control', { 'is-invalid': errors.name })}
                                defaultValue={modalData?.name}
                                ref={register({
                                    required: 'This field is required',
                                    validate: (value) => { return !!value.trim() }
                                })}
                                onChange={handleOnChangeName}
                                name='name' />
                            {errors.name && (<div className='invalid-feedback'>{errors.name.message}</div>)}
                        </div>
                        <div className={classNames('form-group', { 'd-none': shouldHideExtraFields })}>
                            <label className='mb-0 mt-2'>Description</label>
                            <textarea className='form-control' defaultValue={modalData?.description} ref={register} onChange={handleOnChangeDescription} name='description' />
                        </div>
                        {(modalTitle === 'Add Paragraph' || modalTitle === 'Edit Paragraph') ?
                            <div className='form-group'>
                                <ReactEditor store={monacoEditorStore} enableSideBySideView={true} content={modalData?.body} onEditorChange={handleOnEditorChange} />
                            </div> : null}
                        <div className='row mt-4'>
                            {(modalTitle.includes('Edit')) ?
                                <div className='col-auto pt-2'>
                                    <DeleteButton targetStore={deleteItemWithStore?.store} onClick={handleOnDelete} openCloseModal={openCloseModal} removeItem={deleteItemWithStore?.item} />
                                </div> : null}
                            <div className='ml-auto'>
                                <button type="button" className="btn btn-outline-secondary mr-2" data-dismiss="modal" onClick={openCloseModal}>Cancel</button>
                                <button type="submit" className="btn btn-outline-primary mr-3">Update</button>
                            </div>
                        </div>
                    </fieldset>
                </form >
            </>
        </FormModal >
    }

    const paragraphFunction = (paraDetails: ParagraphStore, store: any) => {
        return <div className="card mb-3" id={replaceSpaceWithHyphen(paraDetails.name)} key={uuid()}>
            <div className='card-body'>
                <div className='card-title'>
                    <table width='100%'>
                        <tr>
                            <td className='font-weight-bold'>
                                {paraDetails.name}
                            </td>
                            <td className='text-right'>
                                <StateSelectorButton targetStore={paraDetails} onClick={openStateModel} />
                                <EditIcon onClick={() => { onActionClicked('editParagraph', store, paraDetails) }} title='Edit the paragraph settings'
                                />
                            </td>
                        </tr>
                    </table>
                    <hr />
                </div>
                <div className='paragraph'>
                    <Preview>
                        {parse(paraDetails.body)}
                    </Preview>
                </div>
            </div>
        </div >
    }

    const openStateModel = (store: StateSelectable) => {
        setTargetStore(store)
        setShowStateModal(true)
    }

    useEffect(() => {
        if (templateDetails._id === documentId) {
            setTitle(`${templateDetails.name} | Edit`)
        }
        allStatesStore.getAllStates()
    }, [])

    const deedUserLinks = [{ name: 'Editor', isActive: true }]
    const adminSpecificLinks = [{ name: 'Templates', to: '/templates/' }]
    const allLinks = [...deedUserLinks, ...adminSpecificLinks]
    const links = isDeedUser ? deedUserLinks : allLinks

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <Breadcrumb links={links} />
        {showStateModal ? <StateSelector selectorShow={showStateModal} isLoading={isLoading} templateStore={templateStore} targetStore={targetStore} openCloseStateModal={openCloseStateModal} options={statesWithValueLabel} /> : null}
        <div className="card" style={{ width: '794px' }}>
            <div className="card-header">
                Update Template
            </div>
            {isLoading && modalShow === false ? <Article /> : <div className="card-body p-1">
                {!isLoading && isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' >
                    {apiErrorMessage}
                </div>}
                <div className="main-left-content">
                    {(documentId === templateDetails._id)
                        ? <div>
                            <div className="row">
                                <div className="col-12">
                                    {(templateDetails.content.length !== 0) ?
                                        templateDetails.content.map((list: any) => {
                                            if (list.type === 'paragraph') {
                                                return paragraphFunction(list, templateDetails)
                                            } else {
                                                return null
                                            }
                                        })
                                        : null}
                                </div>
                            </div>
                            <AddParagraphButton onClick={() => {
                                onActionClicked('addParagraph', {}, templateDetails)
                            }} title='Add new paragraph in template' />
                        </div>
                        : null}
                    {modalShow ? articleModalWithChildModal() : null}
                </div>
            </div>}
        </div>
    </Container>
}

export default observer(UpdateTemplatePage)

