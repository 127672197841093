import { action, computed, observable } from 'mobx'
import { DeedImage, DeedImageStore } from './types.d'
import { deleteDeedImages, getOneDocDeedImages, upperTextOfDeedImage, lowerTextOfDeedImage, rearrangeDisplayOrderOfDeedImages, shouldUseRealDeedImage, getOneDeedFiles, deleteDeedFile } from '../api/DeedImagesAction'
import RootStore from './Root'

interface ResponseToComponent {
    success: boolean
    message: string
}

class SingleDocDeedImagesStore implements DeedImageStore {
    public rootStore: RootStore
    @observable public UUID: string
    @observable public isLoading: boolean
    @observable public isApiError: boolean
    @observable public isImagesFetched = false
    @observable public apiErrorMessage: string
    @observable public images: Array<DeedImage>
    @observable public isAllProcessDone = true

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.resetData()
    }

    @action resetData = () => {
        this.apiErrorMessage = ''
        this.isLoading = false
        this.isApiError = false
        this.images = []
        this.UUID = ''
    }

    @computed get getImages() {
        return this.images
    }

    @action
    resetImages() {
        this.images = []
    }

    @action
    setImageFetchedFlag(flag: boolean) {
        this.isImagesFetched = flag
    }

    @action
    setProcessStatus(status: boolean) {
        this.isAllProcessDone = status
    }

    @action
    setAllImages(images: DeedImage[]) {
        this.images = images
    }

    @action
    updateImages(newImages) {
        this.images = [...this.images || [], ...newImages]
    }

    @action
    setLoading(loading: boolean) {
        this.isLoading = loading
    }
    
    removeImage(id: string, index: number, type = '', cb: (response: ResponseToComponent) => void) {
        this.isLoading = true
        if (type === 'deed_research') {
            deleteDeedFile(id).then((removeResponse) => {
                if(removeResponse.data.success === 0) {
                    cb({ success: false, message: removeResponse.data.message })
                }
                this.isLoading = false
                this.images = this.images.filter((image) => image.id.toString() !== id)
                if (this.images.length === 0 ) {
                    this.rootStore.deedResearchStore.fetchDeeds(this.rootStore.deedResearchStore.page, this.rootStore.deedResearchStore.searchItem, this.rootStore.deedResearchStore.filterWithUserId, this.rootStore.deedResearchStore.filterWithFutureSigning, this.rootStore.deedResearchStore.searchByStatus, this.rootStore.deedResearchStore.excludeTestDeeds, this.rootStore.deedResearchStore.filterWithState)
                }
                cb({ success: true, message: removeResponse.data.message })
            }).catch((error) => {
                cb({ success: false, message: error.message })
            })
        } else {
            deleteDeedImages(id).then((removeResponse) => {
                if(removeResponse.data.success === 0) {
                    cb({ success: false, message: removeResponse.data.message })
                }
                this.isLoading = false
                this.images = this.images?.filter((_, i) => i !== index)
                cb({ success: true, message: removeResponse.data.message })
            }).catch((error) => {
                cb({ success: false, message: error.message })
            })
        }
    }

    @action
    setUUID(UUID:string) {
        this.UUID = UUID
        this.isImagesFetched = false
    }

    setUpperText(_id: string, upperText: string, index: number, cb: (response: ResponseToComponent) => void) {
        this.isLoading = true
        upperTextOfDeedImage({ _id, upper_text: upperText }).then((response) => {
            if(response.data.success === 0) {
                cb({ success: false, message: response.data.message })
            }
            this.isLoading = false
            const newImages = [...this.images]
            newImages[index]['upper_text'] = upperText
            this.setAllImages(newImages)
            cb({ success: true, message: response.data.message })
        }).catch((error) => {
            cb({ success: false, message: error.message })
        })
    }

    setLowerText(_id: string, lowerText: string, index: number, cb: (response: ResponseToComponent) => void) {
        this.isLoading = true
        lowerTextOfDeedImage({ _id, lower_text: lowerText }).then((response) => {
            if(response.data.success === 0) {
                cb({ success: false, message: response.data.message })
            }
            this.isLoading = false
            const newImages = [...this.images]
            newImages[index]['lower_text'] = lowerText
            this.setAllImages(newImages)
            cb({ success: true, message: response.data.message })
        }).catch((error) => {
            cb({ success: false, message: error.message })
        })
    }

    setShouldUseRealOrNot(_id: string, yesOrNo: boolean, index: number, cb: (response: ResponseToComponent) => void) {
        this.isLoading = true
        shouldUseRealDeedImage({ _id, should_use_real: yesOrNo }).then((response) => {
            if(response.data.success === 0) {
                cb({ success: false, message: response.data.message })
            }
            this.isLoading = false
            const newImages = [...this.images]
            newImages[index]['should_use_real'] = yesOrNo
            this.setAllImages(newImages)
            cb({ success: true, message: response.data.message })
        }).catch((error) => {
            cb({ success: false, message: error.message })
        })
    }

    rearrangeImages(newImages: DeedImage[], cb: (response: ResponseToComponent) => void) {
        this.isLoading = true
        const reqArrangeImages = newImages.map((image) => {
            return {
                _id: image._id,
                display_order: image.display_order
            }
        })
        rearrangeDisplayOrderOfDeedImages({images: reqArrangeImages}).then((response) => {
            if(response.data.success === 0) {
                cb({ success: false, message: response.data.message })
            }
            this.isLoading = false
            this.setAllImages(newImages)
            cb({ success: true, message: response.data.message })
        }).catch((error) => {
            cb({ success: false, message: error.message })
        })
    }

    getOneDocumentDeedImages = async () =>{
        if (this.isLoading === false) {
            this.isLoading = true
        }
        this.isApiError = false
        this.apiErrorMessage = ''
        new Promise(() => {
            getOneDocDeedImages(this.UUID).then((response => {
                if (response.data.success === 0) {
                    this.isApiError = true
                    this.apiErrorMessage = response.data.message
                }
                const fetchedImages:DeedImage[] = response.data.data.length !== 0 ? response.data.data.map((image) => {
                    return {
                        upper_text: image.upper_text,
                        lower_text: image.lower_text,
                        image_key: image.image_key,
                        id: image.id,
                        _id: image._id,
                        size: image.size,
                        optimized_key: image.optimized_key,
                        optimized_size: image.optimized_size,
                        should_use_real: image.should_use_real,
                        display_order: image.display_order,
                        original_file_name: image.original_file_name
                    }
                }) : []
                this.setAllImages(fetchedImages)
                if (this.isImagesFetched === false) {
                    this.isImagesFetched = true
                }
                if (this.isAllProcessDone === true) {
                    this.isLoading = false
                }
            })).catch((error) => {
                this.isLoading = false
                if (error.request.status === 401) {
                    this.apiErrorMessage = '401'
                } else {
                    this.apiErrorMessage = error.message
                }
                this.isApiError = true
                console.log(error)
            })
        })
    }

    getDeedFiles = async () =>{
        if (this.isLoading === false) {
            this.isLoading = true
        }
        this.isApiError = false
        this.apiErrorMessage = ''
        const response = await getOneDeedFiles(this.UUID)
        if (response.data.success === 0) {
            this.isApiError = true
            this.apiErrorMessage = response.data.message
        }
        const fetchedImages:DeedImage[] = response.data.data.length !== 0 ? response.data.data.map((image) => {
            return {
                upper_text: image.upper_text,
                lower_text: image.lower_text,
                image_key: image.image_key,
                id: image.id,
                _id: image._id,
                size: image.size,
                optimized_key: image.optimized_key,
                optimized_size: image.optimized_size,
                should_use_real: image.should_use_real,
                display_order: image.display_order,
                file_type: image.file_type,
                original_file_name: image.original_file_name,
                uploaded_by: (image.uploaded_by) ? `${image.uploaded_by.first_name} ${image.uploaded_by.last_name}`: '',
                uploaded_at: image.uploaded_at
            }
        }) : []
        this.setAllImages(fetchedImages)
        if (this.isImagesFetched === false) {
            this.isImagesFetched = true
        }
        if (this.isAllProcessDone === true) {
            this.isLoading = false
        }
    }
}

export default SingleDocDeedImagesStore