import React from 'react'
import { Link } from 'react-router-dom'
import SingleTemplateStore from '../../store/SingleTemplateStore'
import { ValueLabel } from '../../store/types.d'
import TimeAgo from 'react-timeago'
import { englishStrings } from '../lawmatics/EnglishString'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import { Cell } from '../table/TableCellText.d'
import _ from 'lodash'
import moment from 'moment'

export const makeValueLabelList = (data: any, allOptions: Array<ValueLabel>) => {
    const returnData:Array<ValueLabel> = []
    for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < allOptions.length; j++) {
            if (data[i].toString() === allOptions[j].value.toString()) {
                returnData.push(allOptions[j])
            }
        }
    }
    return returnData
}

export function replaceSpaceWithHyphen(name: string): string {
    return name.replace(/ /g, '-')
}

interface DeleteButtonProps {
  targetStore: SingleTemplateStore | undefined,
  removeItem: any,
  onClick: (store: any, removeItem: any, openClose: () => void) => void,
  openCloseModal: () => void
}

export const DeleteButton: React.FC<DeleteButtonProps> = ({ targetStore, removeItem, onClick, openCloseModal }) => {
    return <Link
        className='text-danger'
        to='#'
        onClick={() => {
            onClick(targetStore, removeItem, openCloseModal)
        }} > Delete
    </Link>
}

export interface ClientInfo {
  title: string,
  value?: string,
  diffValue?: string
  type?: string
  helpText?: string
  setLink?: any
}

export interface RequestData {
    title: string,
    value: any
}

export const ShowDetails: React.FC<ClientInfo> = ({ title, value, helpText = '', setLink = '' }) => {
    return <div className='row row-hover'>
        <div className='col-sm-4'>
            <b>{title} </b>
            { helpText ? <small className='form-text text-muted mt-0'>{ helpText }</small> : null}
        </div> :
        <div className='col'>{value} {value && setLink }</div>
    </div>
}

export const ShowVisionDetails: React.FC<ClientInfo> = ({ title, value, type = 'String', setLink }) => {
    return <div className='row'>
        <div className='col-sm-4'><b>{title} </b></div> :
        <div className='col'>{ type === 'Image' ? <img src={value} height={80} width={200} style={{ objectFit: 'contain' }}></img> : <span>{value}{value && setLink}</span> }</div>
    </div>
}

export const dateFormat = (ISOdate) => {
    if (ISOdate === '') return ISOdate

    const date = new Date(ISOdate).toISOString().split('T')
    const formatDate = date[0].split('-')
    const returnDate = formatDate[1] + '/' + formatDate[2] + '/' + formatDate[0]
    return returnDate
}

export const dateFormatWithTime = (ISOdate) => {
    if (ISOdate === '') return ISOdate
    const dateWithTime = moment(ISOdate).utc().format('MM/DD/YYYY HH:mm:ss')

    return dateWithTime
}

export const FormateDate: React.FC<Cell> = (props) => {
    const formatter = buildFormatter(englishStrings)
    const newDate = props.value.split('T')
    const formatDate = newDate[0].split('-')
    const dateString = formatDate[1] + '/' + formatDate[2] + '/' + formatDate[0]
    const getTime = newDate[1].split('.')

    return (
        <>
            {dateString + ' ' + getTime[0]}<br/><TimeAgo date={props.value} formatter={formatter}></TimeAgo>
        </>
    )
}

export const isValueEquivalent = (a: any, b: any) => {
    if (!_.isEqual(a, b)) {
        return false
    }
    return true
}

export const ShowTags: React.FC<Cell> = (props) => {
    const value = props.value
    return (
        <>
            {_.join(value, ', ')}
        </>
    )
}

export const formatBytes = (bytes) => {
    if (bytes === 0) return '0 Bytes'
    const k = 1024
    const sizes = ['Bytes', 'KB', 'MB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
}

export const nl2br = (str: string) => {
    return str.replace(/\n/g, '<br>')
}

export const br2nl = (str: string) => {
    return str.replace(/<br>/g, '\n')
}

export const convertToTitleCase = (str: string) => {
    if (!str || typeof str !== 'string') return ''
    
    const words = str.split('_')
    const capitalizedWords = words.map((word) => 
        word.charAt(0).toUpperCase() + word.slice(1)
    )
    return capitalizedWords.join(' ')
}

export const removeCircularReferences = (obj: any) => {
    const seen = new WeakSet()
    function deepClean(value: any) {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) return undefined // Remove circular reference
            seen.add(value)
    
            if (Array.isArray(value)) {
                return value.map(deepClean).filter((item) => item !== null && item !== undefined)
            }
    
            return Object.fromEntries(
                Object.entries(value)
                    .map(([key, val]) => [key, deepClean(val)])
                    .filter(([, val]) => val !== null && val !== undefined)
            )
        }
        return value
    }
    
    return deepClean(obj)
}