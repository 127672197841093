import { action, observable } from 'mobx'
import RootStore from './Root'
import { DeedResearchProps, UserEmailWithStatus, ValueLabel } from './types.d'
import { getDeeds, editCompletedFlagInDeed, editPendingFlagInDeed, editWorkingFlagInDeed, editReviewedFlagInDeed, editDraftedFlagInDeed, getUsersEmailForDeedResearch, editResearchingFlagInDeed, editResearchCompletedFlagInDeed } from '../api/DeedActions'
import { initialTableDataWithPaginationDetails, initialDeeds, defaultAllObj } from './InitialValues'
import axios from 'axios'

interface DeedsData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<DeedResearchProps>
}

interface MarkPendingData {
    _id: string,
    is_pending: boolean,
    pending_notes: string, 
    is_working: boolean,
    is_note_changed: boolean
}

interface MarkReviewData {
    _id: string, 
    is_reviewed: boolean, 
    reviewed_notes: string, 
    is_working: boolean,
    is_note_changed: boolean
}

interface MarkDraftedData {
    _id: string, 
    is_drafted: boolean, 
    drafted_notes: string, 
    is_working: boolean,
    is_note_changed: boolean
}

interface MarkResearchingData {
    _id: string, 
    is_researching: boolean, 
    researching_notes: string, 
    is_note_changed: boolean
}

interface MarkResearchCompletedData {
    _id: string, 
    is_research_completed: boolean, 
    research_completed_notes: string, 
    is_note_changed: boolean
}

class DeedStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public deedsDetails: DeedResearchProps
    @observable public deedsData: DeedsData
    @observable public searchItem: string
    @observable public searchByStatus: Array<ValueLabel>
    @observable public excludeTestDeeds: boolean
    @observable public page: number
    @observable public filterWithFutureSigning: boolean
    @observable public tableRows: Array<DeedResearchProps>
    @observable public isTableView: string
    @observable public filterWithUserId: ValueLabel
    @observable public filterWithState: ValueLabel
    @observable public usersForMarketByOptions: Array<UserEmailWithStatus>
    @observable public isPageOpened: boolean
    @observable public loaderCounter: number
    @observable public controller: AbortController | null = null

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.resetData()
    }

    @action resetData = (): void => {
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.isPageOpened = false
        this.page = 1
        this.deedsDetails = initialDeeds
        this.deedsData = initialTableDataWithPaginationDetails
        this.tableRows = []
        this.usersForMarketByOptions = []
        this.loaderCounter = 0
        this.deedsDetails = initialDeeds
        this.deedsData = initialTableDataWithPaginationDetails
        this.tableRows = []
        this.usersForMarketByOptions = []
        this.loaderCounter = 0
        this.isPageOpened = false
        this.getTableView()
        this.resetFilter()
    }

    @action setIsOpen = (isOpened: boolean): void => {
        this.isPageOpened = isOpened
    }

    @action showLoader() {
        this.loaderCounter ++
        this.isLoading = true
    }

    @action hideLoader () {
        this.loaderCounter --
        if (this.loaderCounter <= 0) {
            this.isLoading = false
        }
    }

    @action resetFilter = () => {
        this.searchItem = ''
        this.filterWithFutureSigning = true
        this.filterWithUserId = defaultAllObj
        this.filterWithState = defaultAllObj
        this.excludeTestDeeds = true
        this.searchByStatus = []
    }

    @action setSearchItemField = (value: string): void => {
        this.searchItem = value
    }

    fetchUserEmail =  () => {
        this.isApiError = false
        this.apiErrorMessage = ''

        getUsersEmailForDeedResearch().then((response) => {
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            const users = response.data.data
            this.usersForMarketByOptions = users.map((list: any) => {
                const data = {
                    value: list._id,
                    label: list.email,
                    status: list.status
                }
                return data
            })
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    getTableView = () => {
        const view = localStorage.getItem('view')
        if(view === null) {
            this.isTableView = 'table_view'
            localStorage.setItem('view', 'table_view')
        } else {
            this.isTableView = view
        }
    }

    setIsTableView = (value: string) => {
        this.isTableView = value
        localStorage.setItem('view', value)
    }
    //get the list of deeds with details
    fetchDeeds = (page: number, searchItem: string, filterWithUserId: ValueLabel, filterWithFutureSigning: boolean, searchByStatus: Array<ValueLabel>, excludeTestDeeds: boolean, filterWithState: ValueLabel): void => {
        
        if (this.controller) {
            this.controller.abort()
        }
        this.controller = new AbortController()

        this.isPageOpened = true
        this.showLoader()
        this.isApiError = false
        this.apiErrorMessage = ''
        let requestData = {}

        for(let i = 0; i< searchByStatus.length; i++) {
           
            if(searchByStatus[i].value.includes('not')) {
                requestData[searchByStatus[i].value.replace('_not', '')] = false
            } else {
                requestData[searchByStatus[i].value] = true
            }
            
        }

        requestData = { ...requestData, page: page, search_item: searchItem.trim(), filterWithUserId: filterWithUserId.value, is_future_signing: filterWithFutureSigning, exclude_test_deeds: excludeTestDeeds, filterWithState: filterWithState.value }
        this.page = page
        this.searchItem = searchItem
        this.filterWithFutureSigning = filterWithFutureSigning
        this.filterWithUserId = filterWithUserId
        this.searchByStatus = searchByStatus
        this.excludeTestDeeds = excludeTestDeeds
        this.filterWithState = filterWithState

        getDeeds(requestData, this.controller.signal).then((response) => {
            this.hideLoader()
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.deedsData = response.data.data
            const pageItems = response.data.data.page_items.map((deedItem) => {
                const stateOfPropertyName = this.rootStore.allStatesStore.statesWithValueLabel.find((state) => state.value.toString() === deedItem.state_of_property.toString() )
                return {
                    ...deedItem,
                    state_of_property: stateOfPropertyName?.label || ''
                }
            })
            this.tableRows = pageItems
        }).catch((error) => {
            if (!axios.isCancel(error)) {
                if (error.request.status === 401) {
                    this.rootStore.authStore.setAuthToken('')
                }
                this.isApiError = true
                this.apiErrorMessage = error.message
            }
            console.log('outside')
            this.hideLoader()
        })
    }
    
    markCompleted(matterId: string, isCompleted: boolean, isWorking: boolean) {
        const data = { _id: matterId, is_completed: isCompleted, is_working: isWorking } 
        this.showLoader()
        editCompletedFlagInDeed(data).then((response) => {
            this.hideLoader()
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.fetchDeeds(this.page, this.searchItem, this.filterWithUserId, this.filterWithFutureSigning, this.searchByStatus, this.excludeTestDeeds, this.filterWithState)
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.hideLoader()
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    markDrafted(matterId: string, isDrafted: boolean, draftedNotes: string, isWorking: boolean, isNoteChanged: boolean) {
        const data: MarkDraftedData = { _id: matterId, is_drafted: isDrafted, is_working: isWorking, drafted_notes: draftedNotes, is_note_changed: isNoteChanged } 
        this.showLoader()
        editDraftedFlagInDeed(data).then((response) => {
            this.hideLoader()
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.fetchDeeds(this.page, this.searchItem, this.filterWithUserId, this.filterWithFutureSigning, this.searchByStatus, this.excludeTestDeeds, this.filterWithState)
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.hideLoader()
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    markWorking(matterId: string, isWorking: boolean) {
        const data = { _id: matterId, is_working: isWorking } 
        this.showLoader()
        editWorkingFlagInDeed(data).then((response) => {
            this.hideLoader()
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.fetchDeeds(this.page, this.searchItem, this.filterWithUserId, this.filterWithFutureSigning, this.searchByStatus, this.excludeTestDeeds, this.filterWithState)
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.hideLoader()
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    markPending(matterId: string, isPending: boolean, pendingNotes: string, isWorking: boolean, isNoteChanged: boolean) {
        const data: MarkPendingData = { _id: matterId, is_pending: isPending, is_working: isWorking, pending_notes: pendingNotes, is_note_changed: isNoteChanged } 
        this.showLoader()
        editPendingFlagInDeed(data).then((response) => {
            this.hideLoader()
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.fetchDeeds(this.page, this.searchItem, this.filterWithUserId, this.filterWithFutureSigning, this.searchByStatus, this.excludeTestDeeds, this.filterWithState)
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.hideLoader()
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    markReviewed(matterId: string, isReviewed: boolean, reviewedNotes: string, isWorking: boolean, isNoteChanged: boolean) {
        const data: MarkReviewData = { _id: matterId, is_reviewed: isReviewed, is_working: isWorking, reviewed_notes: reviewedNotes ,is_note_changed: isNoteChanged } 
        this.showLoader()
        editReviewedFlagInDeed(data).then((response) => {
            this.hideLoader()
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.fetchDeeds(this.page, this.searchItem, this.filterWithUserId, this.filterWithFutureSigning, this.searchByStatus, this.excludeTestDeeds, this.filterWithState)
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.hideLoader()
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    markResearching(matterId: string, isResearching: boolean, researchingNotes: string, isNoteChanged: boolean) {
        const data: MarkResearchingData = { _id: matterId, is_researching: isResearching, researching_notes: researchingNotes ,is_note_changed: isNoteChanged } 
        this.showLoader()
        editResearchingFlagInDeed(data).then((response) => {
            this.hideLoader()
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.fetchDeeds(this.page, this.searchItem, this.filterWithUserId, this.filterWithFutureSigning, this.searchByStatus, this.excludeTestDeeds, this.filterWithState)
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.hideLoader()
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    markResearchCompleted(matterId: string, isResearchCompleted: boolean, researchCompletedNotes: string, isNoteChanged: boolean) {
        const data: MarkResearchCompletedData = { _id: matterId, is_research_completed: isResearchCompleted, research_completed_notes: researchCompletedNotes ,is_note_changed: isNoteChanged } 
        this.showLoader()
        editResearchCompletedFlagInDeed(data).then((response) => {
            this.hideLoader()
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.fetchDeeds(this.page, this.searchItem, this.filterWithUserId, this.filterWithFutureSigning, this.searchByStatus, this.excludeTestDeeds, this.filterWithState)
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.hideLoader()
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }
}

export default DeedStore
