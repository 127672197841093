import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import Container from '../layout/Container'
import { Link, useHistory, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faHistory, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { Paragraph, Props } from '../../store/types.d'
import SingleTemplateStore from '../../store/SingleTemplateStore'
import ParagraphStore from '../../store/Paragraph'
import FormModal from '../commonComponent/FormModal'
import Modal from '../commonComponent/Modal'
import classNames from 'classnames'
import ReactEditor from '../template/ReactEditor'
import parse from 'html-react-parser'
import { replaceSpaceWithHyphen, DeleteButton } from '../commonComponent/commonFuction'
import { Article } from '../commonComponent/DotLoader'
import { Breadcrumb } from '../commonComponent/BreadcrumbComponent'
import { Preview } from '../commonComponent/PreviewStyle'
import uuid from 'react-uuid'
import moment from 'moment'
import _ from 'lodash'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import ImageUploader from '../commonComponent/ImageUploader'
import { toast } from 'react-toastify'
import { TableLoader } from '../commonComponent/Loader'
import RenderRawHtml from '../template/RenderRawHtml'

interface DeleteProps {
    store: SingleTemplateStore
    item: any
}

const initialParagraph = {
    name: '',
    description: '',
    body: '',
    edit_history: [],
    is_all_states: true,
    states: [],
    type: 'paragraph',
    keywords: []
}

interface EditIconProps {
    onClick: () => void
    title?: string
    id?: string
}

const EditIcon: React.FC<EditIconProps> = ({ onClick, title, id }) => {
    return <button
        type='button'
        id={id}
        onClick={onClick}
        className='btn btn-sm mr-1'
        title={title}><FontAwesomeIcon icon={faEdit} /></button>
}

const AddParagraphButton: React.FC<EditIconProps> = ({ onClick, title }) => {
    return <div className="plus-circle text-center my-2">
        <button
            type="button"
            className="btn btn-sm btn-dark plus-button"
            onClick={onClick}
            title={title}>
            <span><FontAwesomeIcon icon={faPlusCircle} /></span>
        </button>
        <span className="add-para d-block ms-2">Add Paragraph</span>
    </div>
}

const UpdateHTMLPage: React.FC<Props> = ({ rootStore }) => {

    const { register, handleSubmit, errors } = useForm()
    const [shouldHideExtraFields, SetShouldHideExtraFields] = useState(false)
    const [modalShow, setModalShow] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const currentUser = rootStore.superAdminStore.superAdminData._id || ''

    const { templateStore, clientStore, monacoEditorStore, debugStore, allUsersStore, setTitle, authStore, singleDocDeedImagesStore } = rootStore

    const { resetLoadingProcess, previewDocument, isLoading } = clientStore
    const { isDeedUser } = authStore
    const { templateDetails, isApiError, apiErrorMessage, getOneHTMLDocumentDetails } = templateStore
    const { allAppUsers } = allUsersStore

    const history = useHistory()

    const { clientId, matterId, documentId, spouse, UUID, isEdited } = useParams<{
        clientId: string,
        matterId: string,
        documentId: string,
        spouse: string,
        UUID: string,
        isEdited: string
    }>()

    const isHTMLDocument = (history.location.pathname.includes('/HTML')) ? true : false
    const [addEditStore, setAddEditStore] = useState<SingleTemplateStore>()
    const [paragraph, setParagraph] = useState<ParagraphStore>()
    const [beforeParagraph, setBeforeParagraph] = useState<Paragraph>(initialParagraph)
    const [deleteItemWithStore, setDeleteItemWithStore] = useState<DeleteProps>()

    const [modalData, setModalData] = useState<ParagraphStore>(new ParagraphStore(initialParagraph))

    const [historyModalShow, setHistoryModalShow] = useState(false)
    const [paragraphForHistory, setParagraphForHistory] = useState<ParagraphStore>()
    const [lastEdit, setLastEdit] = useState<Paragraph | undefined>(undefined)
    const [selected, setSelected] = useState<number | null>(null)

    useEffect(() => {
        if (clientStore.matter._id !== matterId) {
            clientStore.setCurrentMatter(matterId)
        }
        if ((templateDetails !== undefined && templateDetails.name === '') || (templateDetails !== undefined && documentId !== templateDetails._id)) {
            if (previewDocument._id === '' || previewDocument._id !== documentId) {
                const editedDocId = isEdited !== 'false' ? isEdited : ''
                const forSpouse = (spouse === 'true') ? true : false
                getOneHTMLDocumentDetails(matterId, documentId, forSpouse, UUID, editedDocId)
            }
            templateStore.setPreviewDocumentInTemplateDetails()
        }
        if (templateDetails.slug !== '' && singleDocDeedImagesStore.isImagesFetched === true) {
            singleDocDeedImagesStore.resetImages()
            singleDocDeedImagesStore.setImageFetchedFlag(false)
        }
    }, [matterId, resetLoadingProcess, templateDetails, documentId, isHTMLDocument, previewDocument._id, spouse, isEdited, getOneHTMLDocumentDetails, UUID, debugStore, templateStore, singleDocDeedImagesStore, clientStore])

    useEffect(() => {
        allUsersStore.getAllUsers()
        if(templateDetails._id === documentId){
            setTitle(`${templateDetails.name} | Edit`)
        }
    }, [])

    const openCloseModal = () => {
        const show = (modalShow === false) ? true : false
        setModalShow(show)
    }
    const openCloseHistoryModal = () => {
        const show = (historyModalShow === false) ? true : false
        setHistoryModalShow(show)
    }

    const onActionClicked = (action: string, store: any, data: any) => {

        setDeleteItemWithStore({ item: data, store: store })

        if (action === 'addParagraph') {
            setModalData(new ParagraphStore({ ...initialParagraph }))
            setModalTitle('Add Paragraph')
            SetShouldHideExtraFields(false)
            openCloseModal()
            setAddEditStore(data)
        }
        if (action === 'editParagraph') {
            const editPara = {
                name: data.name,
                description: data.description,
                keywords: data.keywords,
                body: data.body,
                edit_history: data.edit_history || undefined,
                type: data.type,
                states: data.states,
                is_all_states: data.is_all_states
            }
            setModalData(new ParagraphStore(editPara))
            setModalTitle('Edit Paragraph')
            openCloseModal()
            setParagraph(data)
            console.log(editPara)
            setBeforeParagraph(editPara)
            SetShouldHideExtraFields(true)
        }
        if (action === 'showHistory') {
            setLastEdit(undefined)
            setParagraph(data)
            // setModalData(data)
            setParagraphForHistory(data)
            openCloseHistoryModal()
        }
    }

    const onSaveModelForAddArticle = async () => {
        console.log('before', beforeParagraph)
        const editedHistory = modalData.edit_history || []
        editedHistory.push({
            edited_by: currentUser, edited_at: new Date().toISOString(),
            before_edit: beforeParagraph
        })
        console.log('con1')
        if (modalTitle === 'Add Paragraph' && addEditStore !== undefined && modalData !== undefined) {
            addEditStore.addNewParagraph(modalData.name, modalData.description, modalData.body || '', editedHistory, (paragraph) => {
                setModalTitle('Edit Paragraph')
                setParagraph(paragraph)
                SetShouldHideExtraFields(true)
                setDeleteItemWithStore({ item: paragraph, store: addEditStore })
            })
        }
        console.log('con2')
        if (modalTitle === 'Edit Paragraph' && paragraph !== undefined) {
            console.log('con2.1')
            if (paragraph.body === modalData?.body && paragraph.name === modalData?.name && paragraph.description === modalData?.description) {
                toast.warning('You have not made any changes.')
                return
            }
            console.log('con2.2')
            paragraph.name = modalData?.name || ''
            paragraph.description = modalData?.description || ''
            paragraph.body = modalData?.body || ''
            console.log('con2.2.1')
            paragraph.edit_history = editedHistory
            console.log('con2.3')
            setBeforeParagraph(paragraph.get())
        }
        console.log('con3')
        templateDetails.saveHTML(matterId, isEdited, spouse, UUID, (htmlId) => {
            if (htmlId !== 'false') {
                history.push(`/clients/${clientId}/matters/${matterId}/HTML-document/${documentId}/${spouse}/${UUID}/${htmlId}`)
                toast.success('Document changes saved')
            } else {
                toast.error('Document changes not saved')
            }
        })
    }

    const handleOnDelete = (store: SingleTemplateStore, removeItem: any, openClose: () => void) => {
        const message = `Are you sure you want to delete "${removeItem.name}" ${removeItem.type} ? This deletes all the history and is not restorable.`
        if (window.confirm(message)) {
            store.remove(removeItem)
            templateDetails.saveHTML(matterId, isEdited, spouse, UUID, (htmlId) => {
                if (htmlId !== 'false') {
                    history.push(`/clients/${clientId}/matters/${matterId}/HTML-document/${documentId}/${spouse}/${UUID}/${htmlId}`)
                    toast.success(`"${removeItem.name}" ${removeItem.type} deleted`)
                } else {
                    toast.error(`"${removeItem.name}" ${removeItem.type} not deleted`)
                }
            })
        }
        openClose()
    }

    const articleModalWithChildModal = () => {

        const handleOnChangeName = (e: any) => {
            modalData.name = e.target.value.trim()
            setModalData(modalData)
        }

        const handleOnChangeDescription = (e: any) => {
            modalData.description = e.target.value.trim()
            setModalData(modalData)
        }

        const handleOnEditorChange = (data: any) => {
            // const beforePara = modalData.get()
            // setBeforeParagraph(beforePara)
            modalData.body = data
            setModalData(modalData)
        }

        const showHiddenFields = () => {
            SetShouldHideExtraFields(!shouldHideExtraFields)
            if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur()
            }
        }

        const popup_title = `${modalTitle}:  ${modalData?.name}`

        return <FormModal modalTitle={popup_title} modalDialogClass='modal-fullscreen' show={modalShow} openCloseModal={openCloseModal}>
            <>
                <form onSubmit={handleSubmit(onSaveModelForAddArticle)} className='modal-body'>
                    <Preview>
                        {templateDetails.isLoading === true && <TableLoader isLoading={templateDetails.isLoading} />}
                        {templateDetails.isApiError && templateDetails.isLoading === false && <div className='responsive alert-danger p-3 mb-4 rounded' > {templateDetails.apiErrorMessage} </div>}
                        <div className='row'>
                            <div className='col'>
                                <button className='btn btn-outline-primary' type='button' onClick={showHiddenFields}> {shouldHideExtraFields ? 'Show' : 'Hide' } Hidden Fields </button>
                            </div>
                            <div className='col text-right'>
                                <button type="button" className="btn btn-outline-secondary mr-2" data-dismiss="modal" onClick={openCloseModal}>Cancel</button>
                                <button type="submit" className="btn btn-outline-primary mr-1">Update</button>
                            </div>
                        </div>
                        <fieldset>
                            <div className={classNames('form-group', { 'd-none': shouldHideExtraFields })}>
                                <label className='mb-0 mt-2'>Title *</label>
                                <input type='text'
                                    className={classNames('form-control', { 'is-invalid': errors.name })}
                                    defaultValue={modalData?.name}
                                    ref={register({
                                        required: 'This field is required',
                                        validate: (value) => { return !!value.trim() }
                                    })}
                                    onChange={handleOnChangeName}
                                    name='name' />
                                {errors.name && (<div className='invalid-feedback'>{errors.name.message}</div>)}
                            </div>
                            <div className={classNames('form-group', { 'd-none': shouldHideExtraFields })}>
                                <label className='mb-0 mt-2'>Description</label>
                                <textarea className='form-control' defaultValue={modalData?.description} ref={register} onChange={handleOnChangeDescription} name='description' />
                            </div>
                            {(modalTitle === 'Add Paragraph' || modalTitle === 'Edit Paragraph') ?
                                <div className='form-group'>
                                    <ReactEditor store={monacoEditorStore} enableSideBySideView={true} content={modalData?.body} onEditorChange={handleOnEditorChange} isSimpleEditor={true} />
                                </div> : null}
                            <div className='row mt-4'>
                                {(modalTitle.includes('Edit')) ?
                                    <div className='col-auto pt-2'>
                                        <DeleteButton targetStore={deleteItemWithStore?.store} onClick={handleOnDelete} openCloseModal={openCloseModal} removeItem={deleteItemWithStore?.item} />
                                    </div> : null}
                                <div className='ml-auto'>
                                    <button type="button" className="btn btn-outline-secondary mr-2" data-dismiss="modal" onClick={openCloseModal}>Cancel</button>
                                    <button type="submit" className="btn btn-outline-primary mr-3">Update</button>
                                </div>
                            </div>
                        </fieldset>
                    </Preview>
                </form >
            </>
        </FormModal>
    }

    const handleRestoreParagraph = () => {
        if (paragraph !== undefined && lastEdit !== undefined) {
            if (window.confirm('Are you sure you want to restore the selected shows paragraph version.')) {
                const editedHistory = paragraph.edit_history || []
                const currentPara = paragraph.get()
                editedHistory.push({
                    edited_by: currentUser, edited_at: new Date().toISOString(),
                    before_edit: currentPara // beforeParagraph
                })

                const editPara = {
                    name: lastEdit.name,
                    description: lastEdit.description,
                    keywords: lastEdit.keywords,
                    body: lastEdit.body,
                    edit_history: editedHistory,
                    type: lastEdit.type,
                    states: lastEdit.states,
                    is_all_states: lastEdit.is_all_states
                }
                paragraph.set(editPara)
                // setParagraph(editPara)
                console.log('con3')
                templateDetails.saveHTML(matterId, isEdited, spouse, UUID, (htmlId) => {
                    if (htmlId !== 'false') {
                        history.push(`/clients/${clientId}/matters/${matterId}/HTML-document/${documentId}/${spouse}/${UUID}/${htmlId}`)
                        toast.success('Document changes saved')
                    } else {
                        toast.error('Document changes not saved')
                    }
                })
                openCloseHistoryModal()
            }
        }
        
    }

    const HistoryModel: React.FC<Props> = ({ rootStore }) => {        
        const [, forceUpdate] = useState(0)

    
        useEffect(() => {
            if (lastEdit === undefined && paragraphForHistory !== undefined && paragraphForHistory.edit_history) {
                setLastEdit(paragraphForHistory.edit_history[paragraphForHistory.edit_history?.length - 1].before_edit)
                setSelected(0)
            }
        }, [lastEdit, selected])
    
        const handleOnChangeParaHistory = (index: number) => {
            setSelected(index)
            const reverseHistory = paragraphForHistory && paragraphForHistory.edit_history && paragraphForHistory.edit_history.reverse() || []
            const selectedPara =  reverseHistory[index].before_edit
            setLastEdit(() => selectedPara)
            forceUpdate((prev) => prev + 1)
        }
    
        return (
            <Modal 
                modalTitle={`History: ${paragraphForHistory?.name}`}
                modalDialogClass='modal-xl' show={historyModalShow}
                openCloseModal={() => {
                    openCloseHistoryModal()
                }}
                saveButtonName='Restore'
                onSaveModal={handleRestoreParagraph}
            >
                <div className="row">
                    <div className="col card" style={{width: 'auto', marginLeft: '15px' }}>
                        <Preview>
                            <div className="" style={{ width: 'auto', padding: '60px 100px 30px 100px', height: '100%' }}>
                                {lastEdit && <RenderRawHtml>{lastEdit.body}</RenderRawHtml>}
                            </div>
                        </Preview>
                    </div>
                    <div className="col-auto" style={{ height: 'calc(100vh - 300px)', width: '350px' }}>
                        <div className='card p-3'>
                            {paragraphForHistory?.edit_history?.reverse().map((detail, index) => {
                                const user = rootStore.allUsersStore.allAppUsers.find(
                                    (data) => data._id?.toString() === detail.edited_by.toString()
                                )
                                if (user) {
                                    return (
                                        <div key={uuid()}>
                                            <Link to="#"  style={{ textDecoration: selected === index ? 'underline': '', color: selected === index ? '#0056b3': '' }} className={selected === index && detail.before_edit ? 'bg-blue-500' : (detail.before_edit) ? '' : 'disabled-link' } onClick={() => handleOnChangeParaHistory(index)}>
                                                {moment(detail.edited_at).format('MM/DD/YYYY hh:mm a')} - {user.first_name} {user.last_name}
                                            </Link>
                                        </div>
                                    )
                                }
                            })}
                            
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }

    const paragraphFunction = (paraDetails: ParagraphStore, store: any) => {
        const uuidForId = uuid()
        const  edit_history = _.get(paraDetails, 'edit_history', [])
        const { name, body } = paraDetails
        const  htmlID =  replaceSpaceWithHyphen(name)

        return <div className="card mb-3" style={{ backgroundColor: (edit_history.length > 0) ? 'lightyellow' : '' }} id={htmlID} key={uuid()}>
            <div className='card-body'>
                <div className='card-title'>
                    <table width='100%'>
                        <tr>
                            <td className='font-weight-bold'>{name}</td>
                            <td className='text-right'>{edit_history.length > 0  && <button className='btn btn-sm'onClick={() => { onActionClicked('showHistory', store, paraDetails) }} key={uuid()} ><FontAwesomeIcon icon={faHistory} /></button>} <EditIcon key={uuid()} id={uuidForId} onClick={() => { onActionClicked('editParagraph', store, paraDetails) }} /></td>
                        </tr>
                    </table>
                    <hr />
                </div>
                <div className='paragraph'>
                    <Preview key={uuid()}>
                        {parse(body)}
                    </Preview>
                </div>
            </div>
            {edit_history.length > 0  && <ReactTooltip anchorId={uuidForId} style={{ backgroundColor: 'rgb(222, 222, 222, 0.7)', color: '#222' }} >
                {edit_history.map((detail) => {
                    const user = allAppUsers.find((data) => data._id?.toString() === detail.edited_by.toString())
                    if (user !== undefined) {
                        return <div key={uuid()}>{moment(detail.edited_at).format('MM/DD/YYYY hh:mm a')} - {user.first_name} {user.last_name}</div>
                    }
                })
                }
            </ReactTooltip>
            }
        </div >
    }

    const deedUserLinks = [
        { name: 'Documents', to: `/clients/${clientId}/matters/${matterId}` },
        { name: 'HTML Editor', isActive: true }
    ]

    const adminSpecificLinks = [
        { name: 'Clients', to: '/clients/' },
        { name: 'Matters', to: `/clients/${clientId}` }
    ]

    const allLink = [...adminSpecificLinks, ...deedUserLinks]
    const links = isDeedUser ? deedUserLinks : allLink

    const isLoadingState = isLoading || templateStore.isLoading
    const isApiErrorState = (!isLoading && isApiError) || (templateStore.isLoading && templateStore.isApiError)
    const apiErrorMessageToShow = templateStore.apiErrorMessage || apiErrorMessage


    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <Breadcrumb links={links} />
        <div className="card" style={{ width: '794px' }}>
            <div className="card-header">
                Update HTML Document
            </div>
            { isLoadingState ? <Article /> : <div className="card-body p-1">
                { isApiErrorState && <div className="responsive alert-danger p-3 mb-4 rounded">
                    {apiErrorMessageToShow}
                </div> }
                <div className="main-left-content">
                    {(documentId === templateDetails._id)
                        ? <div>
                            <div className="row">
                                <div className="col-12">
                                    {(templateDetails.content.length !== 0) ?
                                        templateDetails.content.map((list: any) => {
                                            if (list.type === 'paragraph') {
                                                return paragraphFunction(list, templateDetails)
                                            } else {
                                                return null
                                            }
                                        }) : null}
                                </div>
                            </div>
                            {templateDetails.slug && templateDetails.slug.includes('deed') && <ImageUploader UUID={UUID} rootStore={rootStore} />}
                            <AddParagraphButton onClick={() => {
                                onActionClicked('addParagraph', {}, templateDetails)
                            }} title='Add new paragraph in template' />
                        </div>
                        : null}
                    {modalShow ? articleModalWithChildModal() : null}
                    {historyModalShow === true && <HistoryModel rootStore={rootStore} />}
                </div>
            </div>}
        </div>
    </Container>
}

export default observer(UpdateHTMLPage)

