import React, { useEffect } from 'react'
import Container from '../layout/Container'
import { observer } from 'mobx-react'
import Table from '../table/Table'
import Pagination from '../table/Pagination'
import { TableCellAction } from '../table/TableCellEditButton'
import { Link } from 'react-router-dom'
import { Cell } from '../table/TableCellText.d'
import uuid from 'react-uuid'
import { debounceSearch } from '../../utils/debounce'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Props } from '../../store/types.d'

const TableCellTemplateNames: React.FC<Cell> = (props) => {

    return (
        <>
            {(props.value.length !== 0) ? props.value.map((item) => {
                return <div key={uuid()}>{item}</div>
            }) : 'All' }
        </>
    )
}

const TableCellActiveInactive: React.FC<Cell> = (props) => {
    const value = props.value
    return (
        <>
            {value === true ? <FontAwesomeIcon className='text-success' icon={faCheck} /> : <FontAwesomeIcon className='text-danger' icon={faTimes} />}
        </>
    )
}

const TemplateSpecificPDFStylePage: React.FC<Props> = ({ rootStore }) => {
    const { templateSpecificPDFStyleStore, authStore, setTitle } = rootStore
    const { isAdmin } = authStore
    const { searchItem, isApiError, isLoading, apiErrorMessage, templateSpecificPDFStyleList, resetTemplateSpecificPDFStyleDetails, setSearchItemField, fetchTemplateSpecificPDFStyleData, isPageOpened } = templateSpecificPDFStyleStore

    const loadPage = (newPage: number) => {
        fetchTemplateSpecificPDFStyleData(newPage, searchItem)
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value)
        debounceSearch(() => fetchTemplateSpecificPDFStyleData(1, e.target.value))
    }

    useEffect(() => {
        resetTemplateSpecificPDFStyleDetails()
        setTitle('Template Specific Additional PDF Styles | OathZephyr')
        if(isAdmin && !isPageOpened){
            fetchTemplateSpecificPDFStyleData(1, '')
        }
    }, [setTitle])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                <div className='row ml-auto'>
          Template Specific Additional PDF Style
                    <Link to='/template-specific-additional-pdf-styles/add' className='ml-auto px-3'>Add Template Specific Additional PDF Style</Link>
                </div>
            </div>

            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>

                            <div className='col-auto ml-auto d-flex'>
                                <input className="form-control mb-4 " name='search' value={searchItem} type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>

                        </div>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}

                        <div className='table-responsive'>
                            <Table
                                isLoading={isLoading}
                                unique_key='_id'
                                columns={[
                                    { name: 'title', title: 'Title' },
                                    { name: 'templates', title: 'Templates', component: TableCellTemplateNames },
                                    { name: 'states', title: 'States', component: TableCellTemplateNames },
                                    { name: 'weight', title: 'Weight' },
                                    { name: 'is_active', title: 'Active', component: TableCellActiveInactive },
                                    { name: '_id', title: 'Action', component: TableCellAction }
                                ]}
                                data={templateSpecificPDFStyleList.page_items}
                            ></Table>
                        </div>

                        <Pagination
                            currentPage={templateSpecificPDFStyleList.currentPage}
                            totalPages={templateSpecificPDFStyleList.totalPage}
                            totalItems={templateSpecificPDFStyleList.totalItem}
                            itemsPerPage={templateSpecificPDFStyleList.itemPerPage}
                            isFiltered={templateSpecificPDFStyleList.isFiltered}
                            totalAllItems={templateSpecificPDFStyleList.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(TemplateSpecificPDFStylePage)
