import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import Container from '../../layout/Container'
import { useHistory, useParams } from 'react-router-dom'
import InputField from '../../commonComponent/InputFieldComponent'
import { Breadcrumb } from '../../commonComponent/BreadcrumbComponent'
import { Props, Dictionary } from '../../../store/types.d'
import Table from '../../table/Table'
import TextInfoStore from '../../../store/vision/TexInfo'
import { initialSingleMarriedText } from '../../../store/InitialValues'
import { EditLeftTextInfo, EditRightTextInfo } from '../../commonComponent/EditDeleteLabelDecorationCellComponent'
import TextInfoModel from './TextInfoModal'
import ReactEditor from '../../template/ReactEditor'
import uuid from 'react-uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import htmlToDraft from 'html-to-draftjs'
import { EditorState, ContentState } from 'draft-js'
import FormModal from '../../commonComponent/FormModal'
import { Preview } from '../../commonComponent/PreviewStyle'
import parse from 'html-react-parser'
import { toast } from 'react-toastify'
import { TableLoader } from '../../commonComponent/Loader'


interface EditIconProps {
    onClick: () => void
    title: string
}

const EditIcon: React.FC<EditIconProps> = ({ onClick, title }) => {
    return <button
        type='button'
        onClick={onClick}
        className='btn btn-sm mr-1'
        title={title}>
        <span><FontAwesomeIcon icon={faEdit} /></span>
    </button>
}

const AddUpdateEngagementTemplatePage: React.FC<Props> = ({ rootStore }) => {
    const { register, handleSubmit, errors } = useForm()

    const { engagementTemplateStore, monacoEditorStore, setTitle } = rootStore
    const { isApiError, isLoading, apiErrorMessage, engagementTemplateDetails, addUpdateEngagementTemplate, fetchEngagementTemplates, page, searchItem, resetEngagementTemplateDetails, setEngagementTemplateDetails } = engagementTemplateStore
    const [leftTextInfo, setLeftTextInfo] = useState<TextInfoStore>()
    const [rightTextInfo, setRightTextInfo] = useState<TextInfoStore>()
    const [modalTitle, setModalTitle] = useState('')
    const [showTextInfoModel, setShowTextInfoModel] = useState(false)
    const [editorState, setEditorState] = useState<EditorState>()
    const [modalDataSingle, setModalDataSingle] = useState<string>()
    const [modalDataMarried, setModalDataMarried] = useState<string>()
    const [modalShowForSingle, setModalShowForSingle] = useState(false)
    const [modalShowForMarried, setModalShowForMarried] = useState(false)
    let isUpdateMode = false
    const history = useHistory()
    let { engagementTemplateId } = useParams<{ engagementTemplateId: string}>()
    if (engagementTemplateId) {
        isUpdateMode = true
    } else {
        engagementTemplateId = ''
    }

    useEffect(() => {
        if (isUpdateMode && engagementTemplateId !== engagementTemplateStore.engagementTemplateDetails._id) {
            setEngagementTemplateDetails(engagementTemplateId)
        } else if(!isUpdateMode) {
            engagementTemplateStore.resetEngagementTemplateDetails()
            engagementTemplateStore.fetchDefaultEngagementDetails()
        }
        
    }, [isUpdateMode, engagementTemplateId, engagementTemplateStore, setEngagementTemplateDetails])

    const openCloseTextInfoModal = () => {
        setShowTextInfoModel(!showTextInfoModel)
    }
    const handleClick = () => {
        resetEngagementTemplateDetails()
        history.push('/engagement-templates/')
    }

    const onSubmit = async (data: Dictionary<string>) => {
        const requestData = {
            _id: engagementTemplateId,
            name: data.name
        }

        addUpdateEngagementTemplate(requestData, (engTemplate) => {
            if(engTemplate !== 'false') {
                // engagementTemplateId = engTemplate
                // isUpdateMode = true
                fetchEngagementTemplates(page, searchItem)
                history.push(`/engagement-templates/update/${engTemplate}`)
                toast.success(' EngagementTemplate added')
            } else {
                toast.error('Engagement Template not added')
            }
        })
    }

    const onActionClicked = (action: string, data: any) => {
        if (action === 'addLeftTextInfo') {
            setLeftTextInfo(new TextInfoStore(initialSingleMarriedText))
            setModalTitle('Add Left Text')
            openCloseTextInfoModal()
        }
        if (action === 'editLeftTextInfo') {
            setLeftTextInfo(data)
            setModalTitle('Edit Left Text')
            openCloseTextInfoModal()
        }
        if (action === 'addRightTextInfo') {
            setRightTextInfo(new TextInfoStore(initialSingleMarriedText))
            setModalTitle('Add Right Text')
            openCloseTextInfoModal()
        }
        if (action === 'editRightTextInfo') {
            setRightTextInfo(data)
            setModalTitle('Edit Right Text')
            openCloseTextInfoModal()
        }

        if (action === 'editEmailTextForSingle') {
            const markdownString = (data.email_text.single_text) ? data.email_text.single_text.replace(/pt;/g, 'px;').replace(/<pre style="word-break: break-word; white-space: pre-wrap; text-align: justify;">/g, '<pre>') : ''
            const blocksFromHTML = htmlToDraft(markdownString)
            const { contentBlocks, entityMap } = blocksFromHTML
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
            const editorState = EditorState.createWithContent(contentState)
            setEditorState(editorState)
            setModalDataSingle(data.email_text.single_text)
            setModalTitle('Edit Email Text for Single')
            openCloseSingleModal()
        }

        if (action === 'editEmailTextForMarried') {
            const markdownString = (data.email_text.married_text) ? data.email_text.married_text.replace(/pt;/g, 'px;').replace(/<pre style="word-break: break-word; white-space: pre-wrap; text-align: justify;">/g, '<pre>') : ''
            const blocksFromHTML = htmlToDraft(markdownString)
            const { contentBlocks, entityMap } = blocksFromHTML
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
            const editorState = EditorState.createWithContent(contentState)
            setEditorState(editorState)
            setModalDataMarried(data.email_text.married_text)
            setModalTitle('Edit Email Text for Married')
            openCloseMarriedModal()
        }
    }
    const openCloseSingleModal =  () => {
        setModalShowForSingle(!modalShowForSingle)
    }

    const onEditorSingleStateChange = async () => {
        engagementTemplateDetails.email_text.single_text = modalDataSingle

        const requestData = {
            _id: engagementTemplateId
        }

        addUpdateEngagementTemplate(requestData, (engTemplate) => {
            if(engTemplate !== 'false') {
                toast.success('Email Text for Single is updated')
            } else {
                toast.error('Email Text for Single is not updated')
            }
        })
        openCloseSingleModal()
    }

    const openCloseMarriedModal = () => {
        setModalShowForMarried(!modalShowForMarried)
    }

    const onEditorMarriedStateChange = async () => {
        engagementTemplateDetails.email_text.married_text = modalDataMarried

        const requestData = {
            _id: engagementTemplateId
        }

        addUpdateEngagementTemplate(requestData, (engTemplate) => {
            if(engTemplate !== 'false') {
                toast.success('Email Text for Married updated')
            } else {
                toast.error('Email Text for Married is updated')
            }
        })
        openCloseMarriedModal()
    }

    const emailTextForSingleFunction = (emailTextForSingle: string) => {
        return <div className="card mb-3" id='Email Text For Single' key={uuid()}>
            <div className='card-body'>
                <div className='card-title'>
                    <table width='100%'>
                        <tr>
                            <td className='font-weight-bold'>
                            For Single
                            </td>
                            <td className='text-right'>
                                <EditIcon onClick={() => { onActionClicked('editEmailTextForSingle', engagementTemplateDetails) }} title='Edit Email Text for Single'/>
                            </td>
                        </tr>
                    </table>
                    <hr />
                </div>
                <div className='paragraph'>
                    <Preview>
                        {parse(emailTextForSingle)}
                    </Preview>
                </div>
            </div>
        </div >
    }

    const emailTextForMarriedFunction = (emailTextForMarried: string) => {
        return <div className="card mb-3" id='Email Text For Married' key={uuid()}>
            <div className='card-body'>
                <div className='card-title'>
                    <table width='100%'>
                        <tr>
                            <td className='font-weight-bold'>
                            For Married
                            </td>
                            <td className='text-right'>
                                <EditIcon onClick={() => { onActionClicked('editEmailTextForMarried', engagementTemplateDetails) }} title='Edit Email Text for Married'/>
                            </td>
                        </tr>
                    </table>
                    <hr />
                </div>
                <div className='paragraph'>
                    <Preview>
                        {parse(emailTextForMarried)}
                    </Preview>
                </div>
            </div>
        </div >
    }

    const handleOnEditorChangeSingle = (data: any) => {
        setModalDataSingle(data)
    }

    const handleOnEditorChangeMarried = (data: any) => {
        setModalDataMarried(data)
    }

    const emailTextForSingleModal = () => {
        return <FormModal modalTitle='Edit Email Text for Single' modalDialogClass='modal-fullscreen' show={modalShowForSingle} openCloseModal={openCloseSingleModal}>
            <form onSubmit={handleSubmit(onEditorSingleStateChange)} className='modal-body'>
                {engagementTemplateStore.isLoading === true && <TableLoader isLoading={engagementTemplateStore.isLoading} />}
                <div className='row'>
                    <div className='col text-right'>
                        <button type="button" className="btn btn-outline-secondary mr-2" data-dismiss="modal" onClick={openCloseSingleModal}>Cancel</button>
                        <button type="submit" className="btn btn-outline-primary mr-1">Update Preview</button>
                    </div>
                </div>
                
                <div className='form-group'>
                    <ReactEditor store={monacoEditorStore} enableSideBySideView={true} content={modalDataSingle} onEditorChange={handleOnEditorChangeSingle} needFeatureToChangeEditor={true} isSimpleEditor={true} />
                </div> 
                <div className='row mt-4'>
                    <div className='ml-auto'>
                        <button type="button" className="btn btn-outline-secondary mr-2" data-dismiss="modal" onClick={openCloseSingleModal}>Cancel</button>
                        <button type="submit" className="btn btn-outline-primary mr-3">Update Preview</button>
                    </div>
                </div>
            </form>
        </FormModal >
    }

    const emailTextForMarriedModal = () => {
        return <FormModal modalTitle='Edit Email Text for Married' modalDialogClass='modal-fullscreen' show={modalShowForMarried} openCloseModal={openCloseMarriedModal}>
            <form onSubmit={handleSubmit(onEditorMarriedStateChange)} className='modal-body'>
                {engagementTemplateStore.isLoading === true && <TableLoader isLoading={engagementTemplateStore.isLoading} />}
                <div className='row'>
                    <div className='col text-right'>
                        <button type="button" className="btn btn-outline-secondary mr-2" data-dismiss="modal" onClick={openCloseMarriedModal}>Cancel</button>
                        <button type="submit" className="btn btn-outline-primary mr-1">Update Preview</button>
                    </div>
                </div>
                
                <div className='form-group'>
                    <ReactEditor store={monacoEditorStore} enableSideBySideView={true} content={modalDataMarried} onEditorChange={handleOnEditorChangeMarried} needFeatureToChangeEditor={true} isSimpleEditor={true} />
                </div> 
                <div className='row mt-4'>
                    <div className='ml-auto'>
                        <button type="button" className="btn btn-outline-secondary mr-2" data-dismiss="modal" onClick={openCloseMarriedModal}>Cancel</button>
                        <button type="submit" className="btn btn-outline-primary mr-3">Update Preview</button>
                    </div>
                </div>
            </form>
        </FormModal >
    }

    useEffect(() => {
        if(isUpdateMode){
            if(engagementTemplateDetails._id === engagementTemplateId){
                setTitle(`${engagementTemplateDetails.name} | Vision - Engagement Template`)
            }
        }
        else{
            setTitle('Vision - Engagement Template | Add')
        }
    }, [isUpdateMode, engagementTemplateDetails, engagementTemplateId, setTitle])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <Breadcrumb links={[
            { name: 'Engagement Templates', to: '/engagement-templates/' },
            { name: (isUpdateMode) ? 'Update' : 'Add', isActive: true }
        ]} />

        <div className="card">
            <div className="card-header">
                {isUpdateMode ? 'Update' : 'Add'} Engagement Template
            </div>
            <div className="card-body">
                <div className='row'>
                    <div className='col col col-md-8'>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' >
                            {apiErrorMessage}
                        </div>}
                        {isLoading === true && <TableLoader isLoading={engagementTemplateStore.isLoading} /> }
                        {engagementTemplateDetails._id === engagementTemplateId &&<form onSubmit={handleSubmit(onSubmit)} >
                            <fieldset>
                                <InputField
                                    label='Name *'
                                    fieldName='name'
                                    register={register}
                                    errors={errors.name}
                                    placeholder='Enter Name'
                                    defaultValue={engagementTemplateDetails.name}
                                    required={true}
                                    disabled={isUpdateMode}
                                />
                                {isUpdateMode && <>
                                    <div className='form-group'>
                                        <div>
                                        Left Text
                                            <div className='card mt-2'>
                                                <div className='card-body'>
                                                    <div className='float-right mb-4'>
                                                        <button type='button' className='btn btn-sm btn-outline-primary' onClick={() => {
                                                            onActionClicked('addLeftTextInfo', leftTextInfo)
                                                        }}> + Add</button>
                                                    </div>
                                                    <div className='table-responsive'>
                                                        <Table
                                                            unique_key='_id'
                                                            columns={[
                                                                { name: 'single_text', title: 'For Single', class: 'pre-wrap' },
                                                                { name: 'married_text', title: 'For Married', class: 'pre-wrap' },
                                                                { name: '_id', title: 'Action', component: EditLeftTextInfo },
                                                            ]}
                                                            onTableAction={onActionClicked}
                                                            data={engagementTemplateDetails.left_text}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='form-group'>
                                        <div>
                                        Right Text
                                            <div className='card mt-2'>
                                                <div className='card-body'>
                                                    <div className='float-right mb-4'>
                                                        <button type='button' className='btn btn-sm btn-outline-primary' onClick={() => {
                                                            onActionClicked('addRightTextInfo', rightTextInfo)
                                                        }}> + Add</button>
                                                    </div>
                                                    <div className='table-responsive'>
                                                        <Table
                                                            unique_key='_id'
                                                            columns={[
                                                                { name: 'single_text', title: 'For Single', class: 'pre-wrap' },
                                                                { name: 'married_text', title: 'For Married', class: 'pre-wrap' },
                                                                { name: '_id', title: 'Action', component: EditRightTextInfo },
                                                            ]}
                                                            onTableAction={onActionClicked}
                                                            data={engagementTemplateDetails.right_text}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {console.log(engagementTemplateDetails, 'sgaojgoiejdf')}

                                    <div className='form-group'>

                                        <label>Email Text</label>
                                        {emailTextForSingleFunction(engagementTemplateDetails.preview_email_text.single)}
                                        {emailTextForMarriedFunction(engagementTemplateDetails.preview_email_text.married)}
                                   
                                    </div>
                                </>}
                                <div className='form-group'>
                                    <button type='submit' className='btn btn-outline-primary' disabled={isLoading}>
                                        {isLoading ? 'Loading...' : isUpdateMode ? 'Update' : 'Submit'}
                                    </button>&nbsp;&nbsp;
                                    <button type='button' className='btn btn-outline-danger' onClick={handleClick} disabled={isLoading}>Cancel</button>
                                </div>
                            </fieldset>
                        </form>}
                        {showTextInfoModel ? <TextInfoModel modalTitle={modalTitle} showModel={showTextInfoModel} targetData={modalTitle.includes('Left') ? leftTextInfo : rightTextInfo} engagementTemplateStore={engagementTemplateStore} openCloseForm={openCloseTextInfoModal} /> : null}
                    </div>
                </div>
                {modalShowForSingle ? emailTextForSingleModal() : null}
                {modalShowForMarried ? emailTextForMarriedModal() : null}
            </div>
        </div>
    </Container >
}

export default observer(AddUpdateEngagementTemplatePage)