import { action, observable } from 'mobx'
import RootStore from './Root'
import { getAnalyticsLogs } from '../api/CronLogActions'
import { AnalyticsCronLogInformation } from './types.d'
import { initialTableDataWithPaginationDetails, initialAnalyticsCronLog } from './InitialValues'

interface CronLogData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    isFiltered: boolean
    itemPerPage: number,
    totalAllItems: number,
    page_items: Array<AnalyticsCronLogInformation>
}

class AnalyticsCronLogStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public analyticCronLogDetails: AnalyticsCronLogInformation
    @observable public analyticCronLogData: CronLogData
    @observable public searchItem: string
    @observable public page: number
    @observable public isLoadingInfo: boolean
    @observable public isPageOpened: boolean

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.resetData()
    }

    @action resetData = () => {
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.isPageOpened = false
        this.searchItem = ''
        this.page = 1
        this.analyticCronLogDetails = initialAnalyticsCronLog
        this.isLoadingInfo = false
        this.analyticCronLogData = initialTableDataWithPaginationDetails
    }

    @action setIsOpen = (isOpened: boolean): void => {
        this.isPageOpened = isOpened
    }

    @action resetFilter = () => {
        this.searchItem = ''
    }

    @action setSearchItemField = (value: string): void => {
        this.searchItem = value
    }

    //get the list of cron logs with details
    fetchAnalyticCronLogData = (page: number, searchItem = ''): void => {
        this.isPageOpened = true
        this.searchItem = searchItem
        this.page = page
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = { page: this.page, search_item: this.searchItem.trim() }

        getAnalyticsLogs(requestData).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.analyticCronLogData = response.data.data
            this.analyticCronLogDetails = initialAnalyticsCronLog
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

}

export default AnalyticsCronLogStore
